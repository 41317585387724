<template>
  <div>
    <el-form
      class="form-item-no-mb"
      ref="searchForm"
      inline
      :model="searchForm"
      size="small"
    >
      <el-form-item label="课程名称">
        <el-input
          v-model="searchForm.courseName"
          clearable
          placeholder="请输入课程名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>

    <div class="content-center">
      <div class="empty-box" v-if="!trainingRecord.length">
          <PlaceholderMap text="暂无培训记录噢~" />
        </div>
      <div class="stage-box-list"  v-else>

        <div class="stage-box-list-info" v-for="(item,index) in trainingRecord" :key="index">
          <div class="stage-box-left">
            <div class="stage-box-list-info-l">
              <div class="stage-box-list-info-l-n">
                {{item.courseName}} <span v-if="item.courseTypeValue">{{item.courseTypeValue}}</span>
              </div>
              <div class="stage-box-list-info-l-t">
                <span>学习时长： {{item.learnedDuration?formatSeconds(item.learnedDuration):'0'}}</span>
                <span class="line-box-two"></span>
                <span>开始时间： {{item.startTime||'—'}}</span>
                <span class="line-box-two"></span>
                <span>所属学习计划： {{item.studyName||'—'}}</span>
              </div>
            </div>
          </div>
          <div class="stage-box-list-info-r">
            <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}"   @click="seeDetail(item)"
              >查看详情</el-button
            >
          </div>
        </div>
      </div>
      <el-pagination
          class="fx-row-end pt16"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchForm.total"
        >
        </el-pagination>
    </div>

  </div>
</template>

<script>
import { getTrainingRecordListApi } from '@/api/api'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  components: {
    PlaceholderMap
  },
  data () {
    return {
      searchForm: {
        courseName: '', // 课程名称
        page: 1,
        pageSize: 10,
        total: 1
      },
      trainingRecord: []
    }
  },
  created () {
    this.query()
  },
  methods: {
    query (type) {
      if (type) {
        this.searchForm.page = 1
      }
      getTrainingRecordListApi(this.searchForm).then(res => {
        if (res.code === 200) {
          this.trainingRecord = res.object.results
        }
      })
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.query()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.query()
    },
    reset () {
      this.searchForm = {
        courseName: '', // 课程名称
        page: 1,
        pageSize: 10,
        total: 1
      }
      this.query()
    },
    seeDetail (item) {
      // 1：正在学习类型；2：学习计划课程类型
      if (item.type == 2) {
        this.$router.push({
          path: `/myOnlineCourseDetails?courseId=${item.businessId}&studyPlanId=${item.studyId}`
        })
      } else {
        this.$router.push({
          path: `/onlineCourseDetail?courseId=${item.courseId}&learningCourseId=${item.businessId}&pageValue=trainRecord`
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-center {
  background: #ffffff;
  height: 671px;
  overflow: auto;
  padding: 24px;
  margin-top: 20px;
}
.stage-box-list {
  height:  calc(100vh - 330px);
  overflow: auto;
  .empty-box {
    margin-top: 16px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .stage-box-list-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    margin-bottom: 16px;
    background: linear-gradient(to bottom, #f0f8ff, #ffffff);
    border: 1px solid #e7f4ff;
    border-radius: 8px;
    .stage-box-list-info-l {
      .stage-box-list-info-l-n {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          margin-left: 16px;
          padding: 2px 12px;
          background-color: #0089ff;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 8px 8px 0;
        }
      }
      .stage-box-list-info-l-t {
        display: flex;
        align-items: cenetr;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        .line-box-two {
          display: inline-block;
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background-color: #dcdcdc;
        }
      }
    }
  }
}
.stage-box-left {
  display: flex;
  align-items: center;
}
/deep/ .form-item-no-mb {
  background: #ffffff;
  padding: 16px 24px;
}
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0;
}
.add-group-btn {
  width: 88px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
</style>

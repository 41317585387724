/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-04 15:30:01
 * @LastEditors: wenwen
 * @LastEditTime: 2024-04-30 13:52:47
 */
export default {
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  methods: {
    matchType (fileName) {
      // 后缀获取
      var suffix = ''
      // 获取类型结果
      var result = ''
      try {
        var flieArr = fileName.split('.')
        suffix = flieArr[flieArr.length - 1]
      } catch (err) {
        suffix = ''
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false
        return result
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix
      })
      if (result) {
        result = 'image'
        return result
      };
      // 匹配 excel
      var excelist = ['xls', 'xlsx', 'doc', 'docx', 'pdf', 'ppt', 'pptx']
      result = excelist.some(function (item) {
        return item == suffix
      })
      if (result) {
        result = 'file'
        return result
      };
      // 匹配 视频
      var videolist = ['mp4', 'fiv']
      result = videolist.some(function (item) {
        return item == suffix
      })
      if (result) {
        result = 'video'
        return result
      };
    },
    formatSeconds (value) {
      //  秒
      let second = parseInt(value)
      //  分
      let minute = 0
      //  小时
      let hour = 0
      //  天
      //  let day = 0
      //  如果秒数大于60，将秒数转换成整数
      if (second > 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        if (minute > 60) {
          //  获取小时，获取分钟除以60，得到整数小时
          hour = parseInt(minute / 60)
          //  获取小时后取佘的分，获取分钟除以60取佘的分
          minute = parseInt(minute % 60)
          //  如果小时大于24，将小时转换成天
          //  if (hour > 23) {
          //    //  获取天数，获取小时除以24，得到整天数
          //    day = parseInt(hour / 24)
          //    //  获取天数后取余的小时，获取小时除以24取余的小时
          //    hour = parseInt(hour % 24)
          //  }
        }
      }

      let result = '' + parseInt(second) + '秒'
      if (minute > 0) {
        result = '' + parseInt(minute) + '分' + result
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
      }
      //  if (day > 0) {
      //    result = '' + parseInt(day) + '天' + result
      //  }
      return result
    },
    formatSecondsMin (value) {
      //  秒
      let second = parseInt(value)
      //  分
      let minute = 0
      //  小时
      let hour = 0
      //  天
      //  let day = 0
      //  如果秒数大于60，将秒数转换成整数
      if (second > 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        if (minute > 60) {
          //  获取小时，获取分钟除以60，得到整数小时
          hour = parseInt(minute / 60)
          //  获取小时后取佘的分，获取分钟除以60取佘的分
          minute = parseInt(minute % 60)
          //  如果小时大于24，将小时转换成天
          //  if (hour > 23) {
          //    //  获取天数，获取小时除以24，得到整天数
          //    day = parseInt(hour / 24)
          //    //  获取天数后取余的小时，获取小时除以24取余的小时
          //    hour = parseInt(hour % 24)
          //  }
        }
      }

      let result = '' + parseInt(second)
      if (minute > 0) {
        result = '' + parseInt(minute) + '.' + result
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '.' + result
      }
      //  if (day > 0) {
      //    result = '' + parseInt(day) + '天' + result
      //  }
      return result
    },
    bytesToSize (bytes) {
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      let size = bytes
      let unitIndex = 0

      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024
        unitIndex++
      }

      // 保留两位小数，四舍五入就
      size = Math.round(size * 100) / 100

      return `${size} ${units[unitIndex]}`
    },
    getPreviewUrl (item) {
      const url = encodeURIComponent(this.$Base64.encode(item.sourceFileUrl))
      const http = process.env.VUE_APP_PREVIEW_API + '/fileview/onlinePreview?url='
      let currentUrl = null
      if (item.fileSuffix == 'xls' || item.fileSuffix == 'xlsx') {
        currentUrl = http + url + '&officePreviewType=html'
      } else if (item.fileSuffix == 'ppt' || item.fileSuffix == 'pptx') {
        currentUrl = http + encodeURIComponent(this.$Base64.encode(item.sourceFileUrl + '_toPdf.pdf'))
      } else {
        currentUrl = http + url
      }
      return currentUrl
    },
    async downloadFile (row) {
      const fileStream = await this.$axios.get(row.sourceFileUrl, {
        responseType: 'blob',
        withCredentials: true
      }).catch((error) => {
        console.log(error, 'error')
        return false
      })
      if (!fileStream) {
        return false
      }
      const result = new Promise((resolve, reject) => {
        const url = URL.createObjectURL(fileStream.data)
        const aLink = document.createElement('a')
        aLink.style.display = 'none'
        aLink.href = url
        aLink.setAttribute('download', row.fileName)
        console.log(aLink, 'row.fileName')
        aLink.click()
        URL.revokeObjectURL(url)
        resolve(true)
      })
      return result
    }
  }
}
